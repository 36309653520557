import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  IconButton,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Modal,
  Container,
  Slider,
  Grid,
  Checkbox,
  Alert,
  Snackbar,
  // Customizable Area End
} from "@mui/material";
import EmailListDataListingController, {
  ELargeModalType,
  Props,
  configJSON,
} from "./EmailListDataListingController.web";
import { searchIconLogo } from "./assets";
import Layout from "../../../components/src/Layout.web";
import { ic_back, ic_plus } from "../../../blocks/accountgroups/src/assets";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { icThinDropdown, infoIcon } from "../../../blocks/customisableuserprofiles/src/assets";
import CheckIcon from '@mui/icons-material/Check';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CountryList from "../../multipageforms/src/CountryCodeList";

export default class EmailListDataListing extends EmailListDataListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  SearchTextField = () => {
    return (
      <input
        type="text"
        value={this.state.searchText}
        onChange={this.onChangeSearch.bind(this)}
        style={{ width: "100%", border: "none", borderRadius: "60px" }}
        data-test-id="searchTxt"
      />
    );
  };

  SearchButton = () => {
    return (
      <Button onClick={this.onEmailSearch.bind(this)} data-test-id="searchIcon">
        <img
          src={searchIconLogo}
          width="40px"
          height="35px"
          style={{ borderRadius: "60px" }}
          data-test-id="searchIconImg"
        />
      </Button>
    );
  };

  getResultsSummary = () => {
    const itemsPerPage = 12;
    const totalItems = this.state.groupList.length ?? 0; // Total results
    const startIndex = (this.state.currentPage - 1) * itemsPerPage + 1; // Start index of current page
    const endIndex = Math.min(this.state.currentPage * itemsPerPage, totalItems); // End index of current page
    return `${totalItems ? startIndex : 0} - ${endIndex} of ${totalItems} ${totalItems > 1 ? "results" : "result"}`;
  };

  getSliderMarks = () => {
    return this.modalSteps.map((e, i) => ({
      value: i,
      label: (() => {
        if (i === 0) {
          return <span style={{ position: "absolute", left: "-10px" }}>{e}</span>;
        } else if (i === this.modalSteps.length - 1) {
          return <span style={{ position: "absolute", right: "-10px" }}>{e}</span>;
        } else {
          return e;
        }
      })(),
    }));
  };

  render() {
    
    return (
      <Layout navigation={this.props.navigation} currentRoute='Manage Users'>
      <StyledContainer>
        {/* Header Section */}
        <Box className="header-container">
          <img src={ic_back.default} style={{width: "28px", height: "28px", position: "absolute", top: "30px"}} />
          {/* Title and Description */}
          <Box style={{display: "flex", flexDirection: "column", marginLeft: "80px"}}>
          <Typography variant="h5" fontWeight="bold" style={{
            color: "#00184C",
            fontSize: "24px",
            
          }}>
            Welcome back, {this.state.profile?.attributes?.first_name}!
          </Typography>
          <Typography style={{
            color: "#00184C",
            fontSize: "16px",
            fontWeight: "400"
          }} variant="body2" color="textSecondary">
            Take a look at your organization!
          </Typography>
          </Box>
          {/* Actions (Add User, Filters, Search) */}
          <Box className="header-top">
            <Button
              style={{
                background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                color: "#FFFFFF",
                borderRadius: "26px",
                padding: "10px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              variant="contained"
              color="primary"
              onClick={this.handleOpenModal}
            >
              <img src={ic_plus.default} style={{width: "24px", height: "24px"}} />
              New Campaign
            </Button>
            <IconButton className="filter-button" aria-label="filter">
            <FilterListIcon style={{ color: '#0F172A' }} />
            <span style={{color: "#75809B", fontSize: "14px", fontWeight: 700}}>Filters</span>
            </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '0px',
                  padding: '4px 8px',
                  width: '250px',
                  backgroundColor: "white",
                  height: "38px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #C4C4C6",
                }}
              >
              <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
               <TextField
                  style={{height: "100%"}}
                  placeholder="Search"
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: '14px',
                       height: "100%",   
                      fontFamily: "Inter, sans-serif",
                      color: '#00184C', 
                      fontWeight: 700,
                  },
                  }}
                  value={this.state.searchText}
                  // onChange={this.handleSearchText}
                  fullWidth
                />
              </Box>
          </Box>
        </Box>
  <div style={{ display: "flex", flexDirection: "row", gap: "35px", marginLeft: "80px", marginBottom: "15px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                       <StyledHtmlTooltip  
                            placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Available Campaigns</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                    Available campaigns represent the remaining number of campaigns you may create out of the total number of campaigns your organization has access to. 
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                            </StyledHtmlTooltip>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}>Available Campaigns :</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>{this.getCampaignStats().activeCampaignCount} out of {this.getCampaignStats().totalCampaigns}</Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"  }}>
                   <StyledHtmlTooltip  
                            placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Active Campaigns</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                    Active campaigns represent the number of campaigns that have at least 1 assigned bot and are actively running. 
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                    </StyledHtmlTooltip>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}>Active Campaigns :</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>{this.getCampaignStats().activeCampaignCount}</Typography>
                </div>
              </div>

        {/* Table Section */}
        <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{overflow:"hidden"}}>
              <StyledTableCell>Campaign</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Bot</StyledTableCell>
              <StyledTableCell style={{textAlign: "center"}}>Applied on</StyledTableCell>
              <StyledTableCell style={{opacity: 0, borderRadius: 0}}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.groupList.slice( (this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
              <TableRow key={group.id} hover>
                <StyledTableCell style={{paddingLeft: "36px"}}>{`${group.attributes.campaign_name}`}</StyledTableCell>
                <StyledTableCell>
                  {group.attributes.status === "running" && <StatusButton>
                    {group.attributes.status}
                  </StatusButton>}
                  {group.attributes.status === "paused" && <PausedButton>
                    {group.attributes.status}
                  </PausedButton>}
                  {group.attributes.status === "completed" && <CompletedButton>
                    {group.attributes.status}
                  </CompletedButton>}
{group.attributes.status === "draft" && <DraftButton>
                    {group.attributes.status}
                  </DraftButton>}
{group.attributes.status === "inactive" && <InactiveButton>
                    {group.attributes.status}
                  </InactiveButton>}
                </StyledTableCell>
                <StyledTableCell>{group.attributes.bot_ids.join(",")}</StyledTableCell>
                <StyledTableCell style={{
                    alignItems: "center",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "36px"
                }}>
                
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{display: "flex", gap: "15px", justifyContent: "center"}}>
                  <EditButton style={{ color:"white", backgroundColor: "#00184C"}}>Details</EditButton>
                  <EditButton style={{ backgroundColor:"#E4C9F3", color: "#00184C"}}>Pause</EditButton>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      </StyledContainer>
      <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ marginTop: 2, height: "100%", marginBottom: "40px" }}
    >
      <div style={{width: "120px", height: "1px"}} />
      {/* Pagination */}
      <StyledPagination
        count={Math.ceil(this.state.groupList.length / 12)}
        page={this.state.currentPage}
        shape="rounded"
        siblingCount={0} 
        boundaryCount={1} 
        // onChange={this.handlePageChange}
      />

      {/* Results Summary */}
      <ResultsSummary>
        {this.getResultsSummary()}
      </ResultsSummary>
    </Stack>   
         <Modal
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: '#00184CB2',
                  backdropFilter: 'blur(10px)', // Blur effect
                  transition: 'all 0.5s ease', // Smooth animation
                  marginLeft: "234px"
                },
              },
            }}
            open={this.state.isVisibleModal || this.isTest} onClose={this.handleCloseModal}>
            <StyledBox>
              {(this.state.largeModalType === ELargeModalType.INITIAL || this.isTest) &&
              <Container style={{ overflow: 'auto', padding: "30px 70px", maxWidth: "unset" }}>
                <div style={{ display: "flex", gap: "20px", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Create Campaign</Typography>
                    <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Take a look at your organization!</Typography>
                  </div>
                  <Button
                    style={{
                      background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                      color: "#FFFFFF",
                      padding: "10px 16px",
                      borderRadius: "26px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "700",
                      gap: "8px",
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      height: '44px',
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Copy from Existing Campaign
                  </Button>
                </div>

                <div style={{display: "flex", flexDirection: "column", gap: "20px", marginTop: "20px", maxWidth: "608px"}}>
                  <Stack sx={{ width: '100%', paddingInline: "10px" }} spacing={4}>
                    <StyledSlider
                      value={this.modalSteps.findIndex(e => e === this.state.modalStep)}
                      min={0}
                      max={this.modalSteps.length - 1}
                      step={1}
                      marks={this.getSliderMarks()}
                    />
                  </Stack>
                </div>

                {(this.state.modalStep === "Campaign Information" || this.isTest) && 
                <Box style={{ marginTop: '40px'}}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", marginTop: "60px" }}>Campaign Information</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5}>
                      <Grid item lg={6}>
                        <Box>
                          <Typography style={styles.labelStyle}>Campaign Name</Typography>
                          <TextField
                            type="text"
                            placeholder="Enter campaign name"
                            fullWidth
                            value={this.state.campaignName}
                            onChange={this.onChangeCampaignName}
                            style={styles.textInput}
                          />
                        </Box>
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Select Bot/Link Bots</Typography>
                          <Box
                            sx={{
                              ...styles.textInput,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '14px',
                              cursor: 'pointer',
                              borderRadius: this.state.isBotDropdownOpen ? '8px 8px 0 0' : '8px',
                            }}
                            onClick={this.handleBotSelectionOpen}
                          >
                            <Typography style={{
                              color: this.state.selectedBots?.length ? '#334155' : '#64748B',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}>
                              {this.state.selectedBots?.length 
                                ? this.state.botList
                                    .filter((bot: any) => this.state.selectedBots.includes(bot.id))
                                    .map((bot: any) => bot.attributes.name)
                                    .join(', ')
                                : "eg. **********"}
                            </Typography>
                            <img
                              src={icThinDropdown}
                              style={{
                                transform: this.state.isBotDropdownOpen ? "rotate(180deg)" : "rotate(0)",
                                width: "16px",
                                height: "16px"
                              }}
                            />
                          </Box>
                          {(this.state.isBotDropdownOpen || this.isTest) && (
                            <>
                              <Paper style={{
                                marginTop: '-2px',
                                border: '1px solid #CBD5E1',
                                borderTop: 'none',
                                borderRadius: '0 0 8px 8px',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              }}>
                                {this.state.botList?.map((bot: any) => (
                                  <Box
                                    key={bot.id}
                                    sx={{
                                      padding: '10px 14px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      '&:hover': {
                                        backgroundColor: '#F8FAFC'
                                      }
                                    }}
                                    onClick={() => this.handleBotSelection(bot.id)}
                                  >
                                    <Typography style={{
                                      color: '#334155',
                                      fontSize: '14px',
                                      fontWeight: 700
                                    }}>
                                      {bot.attributes.name}
                                    </Typography>
                                    <Checkbox
                                      checked={this.state.selectedBots.includes(bot.id)}
                                      onChange={() => this.handleBotSelection(bot.id)}
                                      icon={
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            width: 16,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            height: 16,
                                            borderRadius: 4,
                                            border: '1px solid #64748B',
                                            display: 'flex',
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <span
                                          style={{
                                            width: 16,
                                            height: 16,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#00184C',
                                            boxSizing: "border-box",
                                            borderRadius: 4,
                                            display: 'flex',
                                          }}
                                        >
                                          <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                        </span>
                                      }
                                    />
                                  </Box>
                                ))}
                              </Paper>
                              {/* Select all that apply - only shown when dropdown is open */}
                              <Box
                                sx={{
                                  padding: '10px 0px',
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                                onClick={this.handleSelectAllBots}
                              >
                                <Typography style={{
                                  color: '#334155',
                                  fontSize: '14px',
                                  fontWeight: 700
                                }}>
                                  Select all that apply
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Email Cadence</Typography>
                          <Box
                            sx={{
                              ...styles.textInput,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '14px',
                              cursor: 'pointer',
                              borderRadius: this.state.isCadenceOpen ? '8px 8px 0 0' : '8px',
                            }}
                            onClick={this.handleCadenceOpen}
                          >
                            <Typography style={{
                              color: this.state.selectedCadence ? '#334155' : '#64748B',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}>
                              {this.state.selectedCadence || "Select Email Cadence"}
                            </Typography>
                            <img
                              src={icThinDropdown}
                              style={{
                                transform: this.state.isCadenceOpen ? "rotate(180deg)" : "rotate(0)",
                                width: "16px",
                                height: "16px"
                              }}
                            />
                          </Box>
                          {(this.state.isCadenceOpen || this.isTest) && (
                            <Paper style={{
                              marginTop: '-2px',
                              border: '1px solid #CBD5E1',
                              borderTop: 'none',
                              borderRadius: '0 0 8px 8px',
                              maxHeight: '200px',
                              overflowY: 'auto'
                            }}>
                              {[
                                "3 Business Days",
                                "4 Business Days",
                                "5 Business Days",
                                "6 Business Days",
                                "7 Business Days",
                                "8 Business Days",
                                "9 Business Days",
                                "10 Business Days",
                                "11 Business Days",
                                "12 Business Days",
                                "13 Business Days",
                                "14 Business Days",
                                "15 Business Days",
                                "16 Business Days",
                                "17 Business Days",
                                "18 Business Days",
                                "19 Business Days",
                                "20 Business Days",
                                "21 Business Days",
                                "22 Business Days",
                                "23 Business Days",
                                "24 Business Days",
                                "25 Business Days",
                                "26 Business Days",
                                "27 Business Days",
                                "28 Business Days",
                                "29 Business Days",
                                "30 Business Days",
                                "31 Business Days",
                                "32 Business Days",
                                "33 Business Days",
                                "34 Business Days",
                                "35 Business Days",
                                "36 Business Days",
                                "37 Business Days",
                                "38 Business Days",
                                "39 Business Days",
                                "40 Business Days",
                                "41 Business Days",
                                "42 Business Days",
                                "43 Business Days",
                                "44 Business Days",
                                "45 Business Days",
                                "46 Business Days",
                                "47 Business Days",
                                "48 Business Days",
                                "49 Business Days",
                                "50 Business Days",
                                "51 Business Days",
                                "52 Business Days",
                                "53 Business Days",
                                "54 Business Days",
                                "55 Business Days",
                                "56 Business Days",
                                "57 Business Days",
                                "58 Business Days",
                                "59 Business Days",
                                "60 Business Days"
                              ].map((cadence) => (
                                <Box
                                  key={cadence}
                                  sx={{
                                    padding: '10px 14px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    '&:hover': {
                                      backgroundColor: '#F8FAFC'
                                    }
                                  }}
                                  onClick={() => this.handleCadenceSelection(cadence)}
                                >
                                  <Typography style={{
                                    color: '#334155',
                                    fontSize: '14px',
                                    fontWeight: 700
                                  }}>
                                    {cadence}
                                  </Typography>
                                  <Checkbox
                                    checked={this.state.selectedCadence === cadence}
                                    onChange={() => this.handleCadenceSelection(cadence)}
                                    icon={
                                      <span
                                        style={{
                                          boxSizing: "border-box",
                                          height: 16,
                                          display: 'flex',
                                          alignItems: 'center',
                                          borderRadius: 4,
                                          border: '1px solid #64748B',
                                          justifyContent: 'center',
                                          backgroundColor: 'white',
                                          width: 16,
                                        }}
                                      />
                                    }
                                    checkedIcon={
                                      <span
                                        style={{
                                          width: 16,
                                          backgroundColor: '#00184C',
                                          borderRadius: 4,
                                          display: 'flex',
                                          alignItems: 'center',
                                          height: 16,
                                          justifyContent: 'center',
                                          boxSizing: "border-box",
                                        }}
                                      >
                                        <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                      </span>
                                    }
                                  />
                                </Box>
                              ))}
                            </Paper>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                }

                {(this.state.modalStep === "Ideal Customer Profile Defination" || this.isTest) && 
                <Box style={{ marginTop: '40px'}}>
                  <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", marginTop: "60px" }}>Ideal Customer Profile</Typography>
                  <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={5}>
                      <Grid item lg={6}>
                        {/* Company Name */}
                        <Box>
                          <Typography style={styles.labelStyle}>Company Name</Typography>
                          <TextField
                            type="text"
                            placeholder="Enter company name"
                            fullWidth
                            value={this.state.companyName}
                            onChange={this.onChangeCompanyName}
                            style={styles.textInput}
                          />
                        </Box>

                        {/* Target Company Size */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Company Size</Typography>
                          <Box
                            sx={{
                              ...styles.textInput,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '14px',
                              cursor: 'pointer',
                              borderRadius: this.state.isCompanySizeOpen ? '8px 8px 0 0' : '8px',
                            }}
                            onClick={this.handleCompanySizeOpen}
                          >
                            <Typography style={{
                              color: this.state.selectedCompanySizes.length ? '#334155' : '#64748B',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}>
                              {this.state.selectedCompanySizes.length 
                                ? this.state.selectedCompanySizes.join(', ')
                                : "Company Size"}
                            </Typography>
                            <img
                              src={icThinDropdown}
                              style={{
                                transform: this.state.isCompanySizeOpen ? "rotate(180deg)" : "rotate(0)",
                                width: "16px",
                                height: "16px"
                              }}
                            />
                          </Box>
                          {(this.state.isCompanySizeOpen || this.isTest) && (
                            <>
                              <Paper style={{
                                marginTop: '-2px',
                                border: '1px solid #CBD5E1',
                                borderTop: 'none',
                                borderRadius: '0 0 8px 8px',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              }}>
                                {[
                                  "1 - 10",
                                  "11 - 50", 
                                  "51 - 100",
                                  "101 - 500",
                                  "501 - 1,000",
                                  "1,001 - 5,000",
                                  "5,001 - 10,000",
                                  "10,001 - 25,000",
                                  "25,001+"
                                ].map((size) => (
                                  <Box
                                    key={size}
                                    sx={{
                                      padding: '10px 14px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      '&:hover': {
                                        backgroundColor: '#F8FAFC'
                                      }
                                    }}
                                    onClick={() => this.handleCompanySizeSelection(size)}
                                  >
                                    <Typography style={{
                                      color: '#334155',
                                      fontSize: '14px',
                                      fontWeight: 700
                                    }}>
                                      {size}
                                    </Typography>
                                    <Checkbox
                                      checked={this.state.selectedCompanySizes?.includes(size)}
                                      onChange={() => this.handleCompanySizeSelection(size)}
                                      icon={
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            border: '1px solid #64748B',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 16,
                                            height: 16,
                                            borderRadius: 4,
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <span
                                          style={{
                                            width: 16,
                                            height: 16,
                                            backgroundColor: '#00184C',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 4,
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                        </span>
                                      }
                                    />
                                  </Box>
                                ))}
                              </Paper>
                              {/* Select all that apply - only shown when dropdown is open */}
                              <Box
                                sx={{
                                  padding: '10px 0px',
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                                onClick={this.handleSelectAllCompanySizes}
                              >
                                <Typography style={{
                                  color: '#334155',
                                  fontSize: '14px',
                                  fontWeight: 700
                                }}>
                                  Select all that apply
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>

                        {/* Target Company Industry */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Company Industry</Typography>
                          <TextField
                            type="text"
                            placeholder="Company Industry"
                            fullWidth
                            value={this.state.companyIndustry}
                            onChange={this.onChangeCompanyIndustry}
                            style={styles.textInput}
                          />
                        </Box>

                        {/* Target Company Title */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Company Title</Typography>
                          <TextField
                            type="text"
                            placeholder="Company Title"
                            fullWidth
                            value={this.state.companyTitle}
                            onChange={this.onChangeCompanyTitle}
                            style={styles.textInput}
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6}>
                        {/* Target Location */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Location</Typography>
                          <Box
                            sx={{
                              ...styles.textInput,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '14px',
                              cursor: 'pointer',
                              borderRadius: this.state.isLocationOpen ? '8px 8px 0 0' : '8px',
                            }}
                            onClick={this.handleLocationOpen}
                          >
                            <Typography style={{
                              color: this.state.selectedLocations?.length ? '#334155' : '#64748B',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}>
                              {this.state.selectedLocations.length 
                                ? this.state.selectedLocations.join(', ')
                                : "Select Location"}
                            </Typography>
                            <img
                              src={icThinDropdown}
                              style={{
                                transform: this.state.isLocationOpen ? "rotate(180deg)" : "rotate(0)",
                                width: "16px",
                                height: "16px"
                              }}
                            />
                          </Box>
                          {(this.state.isLocationOpen || this.isTest)  && (
                            <>
                              <Paper style={{
                                marginTop: '-2px',
                                border: '1px solid #CBD5E1',
                                borderTop: 'none',
                                borderRadius: '0 0 8px 8px',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              }}>
                                {CountryList.map((country) => (
                                  <Box
                                    key={country.code}
                                    sx={{
                                      padding: '10px 14px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      '&:hover': {
                                        backgroundColor: '#F8FAFC'
                                      }
                                    }}
                                    onClick={() => this.handleLocationSelection(country.name)}
                                  >
                                    <Typography style={{
                                      color: '#334155',
                                      fontSize: '14px',
                                      fontWeight: 700
                                    }}>
                                      {country.name}
                                    </Typography>
                                    <Checkbox
                                      checked={this.state.selectedLocations.includes(country.name)}
                                      onChange={() => this.handleLocationSelection(country.name)}
                                      icon={
                                        <span
                                          style={{
                                            border: '1px solid #64748B',
                                            display: 'flex',
                                            borderRadius: 4,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                            boxSizing: "border-box",
                                            width: 16,
                                            height: 16,
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <span
                                          style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#00184C',
                                            boxSizing: "border-box",
                                            width: 16,
                                            height: 16,
                                            borderRadius: 4,
                                            display: 'flex',
                                          }}
                                        >
                                          <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                        </span>
                                      }
                                    />
                                  </Box>
                                ))}
                              </Paper>
                              {/* Select all that apply - only shown when dropdown is open */}
                              <Box
                                sx={{
                                  padding: '10px 0px',
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                                onClick={this.handleSelectAllLocations}
                              >
                                <Typography style={{
                                  color: '#334155',
                                  fontSize: '14px',
                                  fontWeight: 700
                                }}>
                                  Select all that apply
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>

                        {/* Target Company Revenue */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Company Revenue</Typography>
                          <Box
                            sx={{
                              ...styles.textInput,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '14px',
                              cursor: 'pointer',
                              borderRadius: this.state.isRevenueOpen ? '8px 8px 0 0' : '8px',
                            }}
                            onClick={this.handleRevenueOpen}
                          >
                            <Typography style={{
                              color: this.state.selectedRevenues.length ? '#334155' : '#64748B',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}>
                              {this.state.selectedRevenues.length 
                                ? this.state.selectedRevenues.join(', ')
                                : "Company Revenue"}
                            </Typography>
                            <img
                              src={icThinDropdown}
                              style={{
                                transform: this.state.isRevenueOpen ? "rotate(180deg)" : "rotate(0)",
                                width: "16px",
                                height: "16px"
                              }}
                            />
                          </Box>
                          {(this.state.isRevenueOpen || this.isTest) && (
                            <>
                              <Paper style={{
                                marginTop: '-2px',
                                border: '1px solid #CBD5E1',
                                borderTop: 'none',
                                borderRadius: '0 0 8px 8px',
                                maxHeight: '200px',
                                overflowY: 'auto'
                              }}>
                                {[
                                  "< $10M",
                                  "$10M - $50M",
                                  "$50M - $100M",
                                  "$100M - $500M",
                                  "$500M - $1B",
                                  "$1B - $5B",
                                  "$5B +"
                                ].map((revenue) => (
                                  <Box
                                    key={revenue}
                                    sx={{
                                      padding: '10px 14px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      '&:hover': {
                                        backgroundColor: '#F8FAFC'
                                      }
                                    }}
                                    onClick={() => this.handleRevenueSelection(revenue)}
                                  >
                                    <Typography style={{
                                      color: '#334155',
                                      fontSize: '14px',
                                      fontWeight: 700
                                    }}>
                                      {revenue}
                                    </Typography>
                                    <Checkbox
                                      checked={this.state.selectedRevenues.includes(revenue)}
                                      onChange={() => this.handleRevenueSelection(revenue)}
                                      icon={
                                        <span
                                          style={{
                                            boxSizing: "border-box",
                                            width: 16,
                                            height: 16,
                                            borderRadius: 4,
                                            border: '1px solid #64748B',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'white',
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <span
                                          style={{
                                            width: 16,
                                            height: 16,
                                            borderRadius: 4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#00184C',
                                            boxSizing: "border-box",
                                          }}
                                        >
                                          <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                        </span>
                                      }
                                    />
                                  </Box>
                                ))}
                              </Paper>
                              {/* Select all that apply - only shown when dropdown is open */}
                              <Box
                                sx={{
                                  padding: '10px 0px',
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                                onClick={this.handleSelectAllRevenues}
                              >
                                <Typography style={{
                                  color: '#334155',
                                  fontSize: '14px',
                                  fontWeight: 700
                                }}>
                                  Select all that apply
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Box>

                        {/* Domain Exclusions */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Domain Exclusions</Typography>
                          <TextField
                            type="text"
                            placeholder="abc@example.com"
                            fullWidth
                            value={this.state.domainExclusions}
                            onChange={this.onChangeDomainExclusions}
                            style={styles.textInput}
                          />
                        </Box>

                        {/* Target Company Management Level */}
                        <Box style={{ marginTop: '20px' }}>
                          <Typography style={styles.labelStyle}>Target Company Management Level</Typography>
                          <TextField
                            type="text"
                            placeholder="abc@example.com"
                            fullWidth
                            value={this.state.managementLevel}
                            onChange={this.onChangeManagementLevel}
                            style={styles.textInput}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>}

                <Box style={{ margin: '40px 0', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px', color: '#00184C' }}>
                  <Button 
                    sx={{ ...styles.button, borderColor: '#00184C' }} 
                    variant="outlined" 
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button 
                    data-test-id="saveChangeBtn" 
                    sx={{ ...styles.button, backgroundColor: '#00184C', color: 'white' }} 
                    variant="contained" 
                    onClick={() => this.handleSaveAndNext(this.state.modalStep)}
                  >
                    Save & Next
                  </Button>
                </Box>
              </Container>}
              <img data-test-id="closeBtn" onClick={() => this.state.largeModalType === ELargeModalType.INITIAL ? this.handleCloseModal() : this.setState({largeModalType: ELargeModalType.INITIAL})} src={ic_back.default} style={{zIndex: 1, cursor: "pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px" }} />
              <Snackbar
              open={this.state.isToastOpen || this.isTest}
              autoHideDuration={5000}
              onClose={this.handleCloseToast}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{ 
                position: 'fixed',
                zIndex: 99999
              }}
            >
              <Alert
                onClose={this.handleCloseToast}
                icon={false}
                style={{
                  backgroundColor: "#FFF0F0",
                  color: "#000000",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  borderLeft: "4px solid #960505",
                }}
                action={
                  <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
                    <CloseRoundedIcon fontSize="small" />
                  </IconButton>
                }
              >
                {this.state.toastContent}
              </Alert>
            </Snackbar>
            </StyledBox>
          </Modal>
    </Layout>
    );
  }
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: 'transparent',
  height: 6,
  padding: '30px 0',
  marginLeft: '20px',
  width: 'calc(100% - 20px)',
  '& .MuiSlider-track': {
    border: 'none',
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    height: 4,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#94A3B8',
    height: 3,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    backgroundColor: '#94A3B8',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  '& .MuiSlider-markActive': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    background: '#F6D8FF',
    height: 28,
    width: 28,
    border: '2px solid #00184C',
    borderRadius: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-markLabel': {
    color: "#334155",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "20px",
    maxWidth: "160px",
    whiteSpace: "break-spaces",
    textAlign: "center",
    '&[data-index="0"]': {
      transform: 'translateX(-20px)',
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 234px / 2)', // Adjusting for the sidebar width
  transform: 'translate(-50%, -50%)',
  width: "calc(90% - 235px)",
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  border: "3px solid #FFFFFFE5",
  borderRadius: "16px",
  boxSizing: "border-box",
  height: "90vh",
  padding: "10px",

  "@media (max-height: 720px)": {
    minHeight: "unset",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
    padding: "20px",
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    flex: 1,
    display: "flex",
    textTransform: "capitalize",
    color: "#00184C",
    borderRadius: "24px",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .create-btn": {
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    border: "1px solid #A4DFFF",
    background: " #00184C",
    textTransform: "capitalize",
    color: "white",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .update-btn": {
    border: "1px solid #A4DFFF",
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    display: "flex",
    flex: 1,
    textTransform: "capitalize",
    color: "white",
    borderRadius: "24px",
    height: "44px",
    maxWidth: "210px",
    fontWeight: 700,
  },

  "& .delete-btn": {
    border: "1px solid #7F1D1D",
    display: "flex",
    color: "white",
    height: "44px",
    background: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .textField": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },

  "& .textArea": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",
    width: "100%",
    minHeight: "156px",

    "&::placeholder": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      color: "#64748B"
    },

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },
}));

const styles = {
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
    lineHeight: '22px',
    marginTop: "15px",
    paddingHorizontal: "20px"
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column"
  },
  textInput: {
    border: '1px solid #CBD5E1 !important',
    backgroundColor: "white",
    borderRadius: "8px",
  },
  button: {
    height: '44px',
    width: '207px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    color: '#00184C',
    lineHeight: '22px',
    textTransform: "capitalize",
  }
}


const ResultsSummary = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center", // Align pagination to the left
  },
  "& .MuiPaginationItem-root": {
    color:"#00184C",
    fontWeight: 400,
    "&.Mui-selected": {
      color:"#00184C",
      borderRadius: "50%",
      backgroundColor: "transparent",
      fontWeight: 700,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "white",
  color: "#22223E",
  textAlign: "start",
  borderBottom: "none",
  borderTop: "1px solid #E9E9EA",
  left: "20px"
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif"
  },
  '.header-container': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
  },
  '.header-top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
    flexDirection: "row",
    gap: "20px"
  },
  '.header-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.table-container': {
    maxHeight: 600,
    overflow: 'hidden',
  },
  '.filter-button': {
    padding: theme.spacing(1),
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
    borderRadius: 0, 
    gap: "10px"
  },
}));


const StatusButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D1FAE5",
  color:"#059669" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const PausedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEF3C7",
  color:"#D97706" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));


const CompletedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEE2E2",
  color:"#DC2626" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const DraftButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#93C5FD",
  color:"#1E3A8A" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const InactiveButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D6D3D1",
  color:"#57534E" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#00184C",
  border: "1px solid #00184C",
  borderRadius: "8px",
  padding: "4px 8px",
  fontWeight: "bold",
  width: "75px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white, // LightTooltip background
    color: '#334155', // LightTooltip text color
    boxShadow: "0px 25px 50px 0px #00000017",
    fontSize: "14px", // LightTooltip font-size
    borderRadius: "0px 16px 16px 16px",
    width: "400px",
    padding: "15px",
    marginTop: "-15px !important"
  },
}));