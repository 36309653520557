import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { EmailListItem, AssociatedEmail } from "./types";
import CountryList from "../../multipageforms/src/CountryCodeList";

// Customizable Area End

// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
  addListener: Function
  goBack: Function,
  getParam:Function,
  navigate:Function
}
export enum ELargeModalType {
  INITIAL = "INITIAL",
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavObject;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loader: boolean;
  emailListTitle: string;
  emailListId: number;
  associatedEmailsList: AssociatedEmail[];
  islistEmpty: boolean;
  emailText: string;
  nameText: string;
  searchText: string;
  searchResultMsg: boolean;
  editMode: boolean;
  email: string;
  fullName: string;
  emailId: number | null,
  pageNum: number,
  totalPages: number,
  pageSize: number,
  groupList: any;
  currentPage: number
  token: any;
  profile: any;
  isVisibleModal: boolean;
  largeModalType: any;
  modalStep: any;
  campaignName: string;
  selectedBot: string | null;
  selectedCadence: string | null;
  isBotDropdownOpen: boolean;
  isCadenceOpen: boolean;
  botList: any;
  selectedBots: string[];
  companyName: string;
  selectedCompanySizes: string[];
  companyIndustry: string;
  companyTitle: string;
  selectedLocation: string;
  selectedRevenue: string;
  domainExclusions: string;
  managementLevel: string;
  isCompanySizeOpen: boolean;
  isLocationOpen: boolean;
  isRevenueOpen: boolean;
  selectedRevenues: string[];
  isToastOpen: boolean;
  toastContent: string;
  selectedLocations: string[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailListDataListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addEmailDataAPICallId = ""
  getEmailsAPICallId = ""
  updateEmailAPICallId = ""
  deleteEmailAPICallId = ""
  searchEmailApiCallId = ""
  getCampaignCallId = ""
  isTest: boolean = false;
  getBotCallId = "";
  modalSteps = ["Campaign Information", "Ideal Customer Profile Defination", "Email Template Setup", 'Finish'];
  createCampaignCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.searchResponse = this.searchResponse.bind(this);
    this.deleteEmailresponse = this.deleteEmailresponse.bind(this);
    this.onEmailSearch = this.onEmailSearch.bind(this);
    this.updateEmailResponse = this.updateEmailResponse.bind(this);
    this.deleteEmailData = this.deleteEmailData.bind(this);
    this.apiFetchCall = this.apiFetchCall.bind(this);
    this.updateEmailData = this.updateEmailData.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.calcTotalPages = this.calcTotalPages.bind(this);
    this.clearSearchRedirect = this.clearSearchRedirect.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.updateEmailData = this.updateEmailData.bind(this);
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.onDelete = this.onDelete.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // Customizable Area End
    ];
    this.state = {
      
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      loader: false,
      emailListTitle: '',
      emailListId: 0,
      associatedEmailsList: [],
      islistEmpty: false,
      emailText: '',
      nameText: '',
      searchText: '',
      searchResultMsg: false,
      editMode: false,
      email: '',
      fullName: '',
      emailId: null,
      pageNum: 1,
      totalPages: 1,
      pageSize: 10,
      currentPage: 1,
      groupList: [],
      token: "",
      profile: undefined,
      isVisibleModal: false,
      largeModalType: ELargeModalType.INITIAL,
      modalStep: "Campaign Information",
      campaignName: "",
      selectedBot: null,
      selectedCadence: null,
      isBotDropdownOpen: false,
      isCadenceOpen: false,
      botList: [],
      selectedBots: [],
      companyName: '',
      selectedCompanySizes: [],
      companyIndustry: '',
      companyTitle: '',
      selectedLocation: '',
      selectedRevenue: '',
      domainExclusions: '',
      managementLevel: '',
      isCompanySizeOpen: false,
      isLocationOpen: false,
      isRevenueOpen: false,
      selectedRevenues: [],
      isToastOpen: false,
      toastContent: '',
      selectedLocations: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id  && responseJson) {
      this.setState({ loader: false })
           if (apiRequestCallId === this.updateEmailAPICallId) {
            this.updateEmailResponse()
          } else if (apiRequestCallId === this.deleteEmailAPICallId) {
            this.deleteEmailresponse(responseJson)
          } else if (apiRequestCallId === this.searchEmailApiCallId) {
            this.searchResponse(responseJson)
          }  if (apiRequestCallId === this.getCampaignCallId) {
            this.setState({groupList: responseJson.data ?? []})
          } else if (apiRequestCallId === this.getBotCallId) {
            this.setState({botList: responseJson.data})
          }
        }  
    // Customizable Area End

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createCampaignCallId) {
        if (responseJson && responseJson.data) {
          // Campaign created successfully
          this.setState({ modalStep: "Email Template Setup" });
        } else {
          // Handle error case
          alert("Failed to create campaign. Please try again.");
        }
      }
    }
  }

  onChangeSearch = (event: {target: {value: string}}) => {
    this.setState({ searchText: event?.target?.value })
  }

  // Customizable Area Start
  async componentDidMount() {
      const emailListId = await getStorageData('emailListId');
      const emailListTitle = await getStorageData('emailListTitle');
      const emailId = emailListId ? parseInt(emailListId) : 0;
      this.setState({
        emailListId: emailId,
        emailListTitle: emailListTitle || '',
      });
      this.getAssociatedEmails(emailId);

    getStorageData("token").then((token) => {
      this.onGettingCampaign(token);
      this.onGettingBots(token);
      this.setState({token: token})
    });
    getStorageData("profile").then((profile) => {
      this.setState({profile: JSON.parse(profile)})
    });
  }

  onDelete = (item: number|string|undefined) => {
    this.deleteEmailData(item);
  }

  calcTotalPages = () => {
    let pageSize= this.state.pageSize
    let emailListTitle =  this.state.associatedEmailsList.length
    let totalPages = (emailListTitle/ pageSize);
    this.setState({ totalPages });
  }

  clearSearchRedirect = () => {
    this.setState({ searchResultMsg: false}, () => {
      this.getAssociatedEmails(this.state.emailListId);
    });
  }

  handleChangeEmail = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.associatedEmailsList[index].email = event?.target?.value
    this.setState({ associatedEmailsList: this.state.associatedEmailsList });
  }

  handleNameChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.associatedEmailsList[index].full_name = event?.target?.value
    this.setState({ associatedEmailsList: this.state.associatedEmailsList });
  }

  getAssociatedEmails = async (accountId: number) => {
    this.setState({loader: true})
    this.getEmailsAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}/${accountId}`
    });
  };

  updateEmailData = async (index: number) => {
    let httpBody = {
      user_id: this.state.associatedEmailsList[index].id,
      email: this.state.associatedEmailsList[index].email,
      full_name: this.state.associatedEmailsList[index].full_name
    }
    
    this.updateEmailAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}/${configJSON.updateEmailApiEndPoint2}`,
      httpBody: httpBody
    });
  }

  deleteEmailData = async (accountId: number|string|undefined) => {
    let httpBody = {
      account_id: accountId
    }
    this.deleteEmailAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}${configJSON.deleteEmailDataApiEndPoint2}`,
      httpBody: httpBody
    });
  }

  onEmailSearch = async () => {
    this.searchEmailApiCallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}${configJSON.searchAssociatedEmailfromListApiEndPoint}${this.state.searchText}`,
    });
  }
  
  apiFetchCall = async (data:{[key: string]: string |number |object|[] | boolean}) => {
    const { contentType, method, endPoint, httpBody } = data
    const headers = {
      'Content-Type': contentType,
      token: configJSON.token
    }
    const requestDataMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    requestDataMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers))
    requestDataMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint)
    requestDataMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method)
    httpBody ? requestDataMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody))
      : requestDataMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    runEngine.sendMessage(requestDataMessage.id, requestDataMessage);
    return requestDataMessage.messageId;
  }

  updateEmailResponse = () => {
    this.setState({
      loader: false,
      associatedEmailsList: this.state.associatedEmailsList
    }, () => {
      alert("Data Updated Successfully");
    if(!this.state.searchResultMsg){
      this.getAssociatedEmails(this.state.emailListId)
    } else {
      this.onEmailSearch()
    }
    });
  }

  deleteEmailresponse = (response: {message: string}) => {
    const isDelete = window.confirm("Are you sure you want to delete this email list?");
    if (isDelete) {
      alert(response.message)
      this.getAssociatedEmails(this.state.emailListId)
    }
    else {
      alert("Email Deletion Canceled");
    }
  }

  searchResponse = (response: EmailListItem) => {   
    if(response.message === "Records Not Found") {
      this.setState({ associatedEmailsList: [], searchResultMsg: false })
    } else {
      this.setState({ associatedEmailsList: response.data, searchResultMsg: true})
    }
  }

  handlePreviousPage = () => {
    this.setState(prevState => ({
      pageNum: Math.max(prevState.pageNum - 1, 1),
    }));
  }

  handleNextPage = () => {
    this.setState(prevState => ({pageNum: prevState.pageNum + 1 }), () => {
      this.calcTotalPages();
      this.deleteEmailData(0);
      this.getAssociatedEmails(this.state.emailListId);
    });
  }

  onGettingCampaign = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_projecttemplates/campaigns"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

getCampaignStats = () => {
    // Extract total number of campaigns
    const totalCampaigns = this.state.groupList.length;
    
    // Filter campaigns that have status "running"
    const activeCampaigns =  this.state.groupList.filter((campaign: any) => 
        campaign.attributes.status.toLowerCase() === "running"
    );
    
    // Get the number of active campaigns
    const activeCampaignCount = activeCampaigns.length;
    
    return {
        totalCampaigns,
        activeCampaignCount
    };
}

  handleCloseModal = () => {
    this.setState({ isVisibleModal: false, modalStep: "Campaign Information" });
  };

  handleOpenModal = () => {
    this.setState({ isVisibleModal: true });
  };

  onChangeCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ campaignName: e.target.value });
  };

  handleBotSelectionOpen = () => {
    this.setState(prevState => ({ 
      isBotDropdownOpen: !prevState.isBotDropdownOpen,
      isCadenceOpen: false 
    }));
  };

  handleCadenceOpen = () => {
    this.setState({ isCadenceOpen: !this.state.isCadenceOpen });
  };


onGettingBots = (token: string) => {
  const header = {
    "Content-Type": "application/json",
    token: token,
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getBotCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "/bx_block_projecttemplates/bots"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ companyName: e.target.value });
};

onChangeCompanyIndustry = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ companyIndustry: e.target.value });
};

onChangeCompanyTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ companyTitle: e.target.value });
};

onChangeDomainExclusions = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ domainExclusions: e.target.value });
};

onChangeManagementLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ managementLevel: e.target.value });
};

handleCompanySizeOpen = () => {
  this.setState({ isCompanySizeOpen: !this.state.isCompanySizeOpen });
};

handleLocationOpen = () => {
  this.setState({ isLocationOpen: !this.state.isLocationOpen });
};

handleRevenueOpen = () => {
  this.setState({ isRevenueOpen: !this.state.isRevenueOpen });
};

  // Customizable Area End

  // Add new handler methods
  handleBotSelection = (botId: string) => {
    const selectedBots = [...(this.state.selectedBots || [])];
    const botIndex = selectedBots.indexOf(botId);
    
    if (botIndex === -1) {
      selectedBots.push(botId);
    } else {
      selectedBots.splice(botIndex, 1);
    }
    
    this.setState({ selectedBots });
  };

  handleSelectAllBots = () => {
    const allBotIds = this.state.botList?.map((bot: any) => bot.id);
    this.setState({ 
      selectedBots: this.state.selectedBots?.length === this.state.botList?.length ? [] : allBotIds,
    });
  };

  handleCloseToast = () => {
    this.setState({ isToastOpen: false });
  };

  validateStep2Form = () => {
    const {
      selectedCompanySizes,
      selectedRevenues,
      companyIndustry,
      companyTitle,
      selectedLocation,
      managementLevel,
    } = this.state;

    const hasSelection = 
      selectedCompanySizes.length > 0 ||
      selectedRevenues.length > 0 ||
      companyIndustry.trim() ||
      companyTitle.trim() ||
      selectedLocation.trim() ||
      managementLevel.trim();

      if (!hasSelection) {
      this.setState({
        isToastOpen: true,
        toastContent: "You must select at least 1 filtering option in order to proceed."
      });
      return false;
    }
    return true;
  };

  handleSaveAndNext = async (modalSteps: any) => {
    if (modalSteps === "Campaign Information") {
      this.setState({ modalStep: "Ideal Customer Profile Defination" });
    } else if (modalSteps === "Ideal Customer Profile Defination") {
      if (!this.validateStep2Form()) {
        return;
      }
      const campaignData = {
        campaign: {
          campaign_name: this.state.campaignName,
          bot_id: this.state.selectedBots[0],
          email_cadence: this.state.selectedCadence,
          company_name: this.state.companyName,
          target_company_location: this.state.selectedLocations.join(","),
          target_company_size: this.state.selectedCompanySizes.join(','),
          target_company_revenue: this.state.selectedRevenues.join(","),
          target_company_industry: this.state.companyIndustry,
          domain_exclusions: this.state.domainExclusions,
          target_company_title: this.state.companyTitle,
          target_company_management_level: this.state.managementLevel
        }
      };

      const header = {
        "Content-Type": "application/json",
        token: this.state.token,
      };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createCampaignCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/bx_block_projecttemplates/campaigns"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(campaignData)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleCancel = () => {
    this.setState({isVisibleModal: false});
  };

  handleCompanySizeSelection = (size: string) => {
    const selectedSizes = [...(this.state.selectedCompanySizes || [])];
    const sizeIndex = selectedSizes.indexOf(size);
    
    if (sizeIndex === -1) {
      selectedSizes.push(size);
    } else {
      selectedSizes.splice(sizeIndex, 1);
    }
    
    this.setState({ selectedCompanySizes: selectedSizes });
  };

  handleSelectAllCompanySizes = () => {
    const allSizes = [
      "1 - 10",
      "11 - 50", 
      "51 - 100",
      "101 - 500",
      "501 - 1,000",
      "1,001 - 5,000",
      "5,001 - 10,000",
      "10,001 - 25,000",
      "25,001+"
    ];
    
    this.setState({ 
      selectedCompanySizes: this.state.selectedCompanySizes?.length === allSizes.length ? [] : allSizes,
    });
  };

  handleRevenueSelection = (revenue: string) => {
    const selectedRevenues = [...(this.state.selectedRevenues || [])];
    const revenueIndex = selectedRevenues.indexOf(revenue);
    
    if (revenueIndex === -1) {
      selectedRevenues.push(revenue);
    } else {
      selectedRevenues.splice(revenueIndex, 1);
    }
    
    this.setState({ selectedRevenues: selectedRevenues });
  };

  handleSelectAllRevenues = () => {
    const allRevenues = [
      "< $10M",
      "$10M → $50M",
      "$50M → $100M",
      "$100M → $500M",
      "$500M → $1B",
      "$1B → $5B",
      "$5B +"
    ];
    
    this.setState({ 
      selectedRevenues: this.state.selectedRevenues?.length === allRevenues.length ? [] : allRevenues,
    });
  };

  handleCadenceSelection = (cadence: string) => {
    this.setState({ 
      selectedCadence: cadence,
      isCadenceOpen: false 
    });
  };
  
  handleLocationSelection = (countryName: string) => {
    const selectedLocations = [...(this.state.selectedLocations || [])];
    const locationIndex = selectedLocations.indexOf(countryName);
    
    if (locationIndex === -1) {
      selectedLocations.push(countryName);
    } else {
      selectedLocations.splice(locationIndex, 1);
    }
    
    this.setState({ selectedLocations });
  };

  handleSelectAllLocations = () => {
    const allLocations = CountryList.map(country => country.name);
    
    this.setState({ 
      selectedLocations: this.state.selectedLocations?.length === allLocations.length ? [] : allLocations,
    });
  };

  resetState = () => {
    this.setState({
      isVisibleModal: false,
      largeModalType: ELargeModalType.INITIAL,
      modalStep: "Campaign Information",
      campaignName: "",
      selectedBot: null,
      selectedCadence: null,
      isBotDropdownOpen: false,
      isCadenceOpen: false,
      selectedBots: [],
      companyName: "",
      selectedCompanySizes: [],
      companyIndustry: "",
      companyTitle: "",
      selectedLocation: "",
      selectedRevenue: "",
      domainExclusions: "",
      managementLevel: "",
      isCompanySizeOpen: false,
      isLocationOpen: false,
      isRevenueOpen: false,
      selectedRevenues: [],
      selectedLocations: [],
      isToastOpen: false,
      toastContent: ""
    });
  };

  handleCampaignStatusUpdate = (campaignId: string, newStatus: string) => {
    // Validate status is one of: running, paused, completed
    const validStatuses = ['running', 'paused', 'completed'];
    if (!validStatuses.includes(newStatus)) {
      this.setState({
        isToastOpen: true,
        toastContent: "Invalid campaign status provided"
      });
      return;
    }

    this.setState({
      isToastOpen: true,
      toastContent: `Campaign ${campaignId} status updated to ${newStatus}`
    });
  };
}
