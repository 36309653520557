import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from "react";
import {
    Box,
    styled,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Avatar,
} from "@mui/material";
import { getStorageData,removeStorageData, setStorageData } from "framework/src/Utilities";
import { useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";


interface Props {
    children: ReactNode;
    currentRoute?: string;
    navigation: any;
    updatedProfile?: any;
}

const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
  ];

const Layout: FunctionComponent<Props> = (props) => {
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [isAdminOpen, setAdminOpen] = useState((props?.currentRoute === "Administration" || props?.currentRoute === "Manage Users"));
    const [isAdmin, setIsAdmin] = useState(false);
    const [profile, setProfile] = useState<any>();
    const [activeMenuItem, setActiveMenuItem] = useState<any>(findMenuItemByUrl()?.label);
    const toggleAdminMenu = () => {
        setAdminOpen((prev) => !prev);
        if (isSettingsOpen) setSettingsOpen(false);
    };

    function findMenuItemByUrl() {
        // Get the current URL from the browser window
        const currentUrl = window.location.href;
    
        // Extract the last segment of the URL
        const urlSegment = currentUrl.split("/").pop();

        // Recursive helper function to search through nested children
        function findItem(items: any): any {
            for (const item of items) {
                if (item.path && item.path === urlSegment) {
                    return item;
                }
                if (item.children) {
                    const foundChild = findItem(item.children);
                    if (foundChild) {
                        return foundChild;
                    }
                }
            }
            return null;
        }
    
        return findItem(menuItems);
    }

    const latestProfile = props.updatedProfile || profile;

    const getProfileCallId = useRef("");

    const {
        sendNetworkRequest,
        setReceiveCallback,
        subscribe,
        unsubscribeFromMessage,
      } = useRunEngine();

      const { extractNetworkResponse } = useBlockHelpers();

      const receive = (from: string, message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const { apiRequestCallId, responseJson } =
            extractNetworkResponse(message);
          if (
            apiRequestCallId === getProfileCallId.current
          ) {
            setStorageData("profile", JSON.stringify(responseJson?.data))
            setProfile(responseJson?.data)
          }
        }
        // Customizable Area End
      };

      useEffect(() => {
        setReceiveCallback(receive);
    
        subscribedMessages.forEach((message) => subscribe(message));
        
        return () => {
            subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        };
    }, []);

      useEffect(() => {
        getStorageData("token").then((token) => getStorageData("account_id").then((accountId) => getProfile(token, accountId)) )
      }, []);

      const getProfile = (token: string, accountId: string) => {
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
    
        sendNetworkRequest(
            getProfileCallId,
            "GET",
            `/account_block/accounts/show?account_id=${accountId}`,
            headers,
        );
      };

    const toggleSettingsMenu = () => {
        setSettingsOpen((prev) => !prev);
        if (isAdminOpen) setAdminOpen(false);
    };
    
    const contact=()=>{
        props.navigation.navigate("Contactus")
    }

    useEffect(() => {
        getStorageData("role").then((role) => {
            if (role === "Administrator") {
                setIsAdmin(true)
            }
            
        });
    }, []);

    return (
        <Container>
            <div className="sideBar">
                <img
                    src={require("./sideBarLogo.png").default}
                    style={{
                        width: "118px",
                        objectFit: "contain",
                        marginBlock: "30px",
                    }}
                    alt="Logo"
                />
                <List className="menuList">
                    {menuItems.filter(e => isAdmin || e.label !== "Administration").map((item, index) =>
                        item.children ? (
                            <div key={index}>
                                <ListItem
                                    button
                                    className="menuItem"
                                    onClick={
                                        item.label === "Administration"
                                            ? toggleAdminMenu
                                            : toggleSettingsMenu
                                    }                                >
                                    {item.icon}
                                    <ListItemText primary={item.label} />
                                    <img 
                                        src={require("./ic_dropdown.png").default} 
                                        style={{
                                            width: "24px", 
                                            height: "24px", 
                                            transform: item.label === "Administration" 
                                                ? (isAdminOpen ? "rotate(0deg)" : "rotate(180deg)")
                                                : (isSettingsOpen ? "rotate(0deg)" : "rotate(180deg)")
                                        }} 
                                    />
                                </ListItem>
                                <Collapse  in={
                                            item.label === "Administration"
                                                ? isAdminOpen
                                                : isSettingsOpen
                                        } timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children.map((child, childIndex) => (
                                            <ListItem
                                                button
                                                key={childIndex}
                                                className={`menuItem childItem ${activeMenuItem === child.label ? 'active' : ''}`} // Updated className
                                                onClick={() => {setActiveMenuItem(child.label); props.navigation.navigate(child.screen); console.log("item", child.label)}}
                                            >
                                                <ListItemText primary={child.label} style={{
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ) : (
                            <ListItem key={index} 
                            className={`menuItem ${activeMenuItem === item.label ? 'active' : ''}`} 
                                onClick={() => {setActiveMenuItem(item.label); props.navigation.navigate(item.screen); console.log("pa", item.label)}} 
                            >
                                {item.icon}
                                {item.label=="Contact Us"?(
                                <ListItemText primary={item.label} onClick={contact} />
                                ):(
                                    <ListItemText primary={item.label}  />
                                )}
                            </ListItem>
                        )
                    )}
                </List>
                <div className="userInfo">
                    {latestProfile && 
                    <>
                     <Avatar className="avatar">{`${latestProfile?.attributes?.first_name[0]}${latestProfile?.attributes?.last_name[0]}`}</Avatar>
                     <Box className="userDetails">
                         <p className="userName">{`${latestProfile?.attributes?.first_name} ${latestProfile?.attributes?.last_name}`}</p>
                         <p className="userOrg">Organization</p>
                     </Box>
                     </>
                    }
                    <Box onClick={() => {
                        removeStorageData("token");
                        setStorageData("token", undefined);
                        props.navigation.navigate("EmailAccountLoginBlock");
                    }} style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", cursor: "pointer"}}>
                        <img src={require("./ic_logout.png").default} style={{ width: "24px", height: "24px" }} />
                        <p className="logout" style={{fontSize: "16px", fontWeight: 400, color: "white"}}>log out</p>
                    </Box>
                </div>
            </div>
            <div className="content">{props.children}</div>
        </Container>
    );
};

export default Layout;

// Sidebar items
const menuItems = [
    { label: "Home", icon: <img src={require("./ic_home.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Notifications", icon: <img src={require("./ic_noti.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Bots", screen: "Bots", path: "/Bots", icon: <img src={require("./ic_bot.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Campaigns", screen: "Campaigns", path: "/Campaigns", icon: <img src={require("./ic_campaign.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Help Center", icon: <img src={require("./ic_help.png").default} style={{ width: "24px", height: "24px" }} /> },
    { label: "Settings", icon: <img src={require("./ic_settings.png").default} style={{ width: "24px", height: "24px" }} /> ,
    children: [
        {
            label: "Profile",
            screen: "UserProfileBasicBlock", 
            path: "Profile",
        },
        {
            label: "Notifications",
        },
        {
            label: "Terms & Conditions",
        },
    ],},
    { label: "Contact Us", icon: <img src={require("./ic_contact_us.png").default} style={{ width: "24px", height: "24px" }} /> },
    {label: "Administration",
        icon: <img src={require("./ic_admin.png").default} style={{ width: "24px", height: "24px" }} />,
        children: [
            {
                label: "Manage Users",
                screen: "AccountGroups",
                path: "AccountGroups"
            },
            {
                label: "Billing",
            },
        ],
    },
];

// Styled components
const Container = styled(Box)({
    width: "100vw",
    height: "100%",
    display: "flex",

    "& .sideBar": {
        width: "235px",
        height: "100%",
        backgroundColor: "#002553",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px 0px",
        boxSizing: "border-box"
    },

    "& .menuList": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "20px"
    },

    "& .menuItem": {
        color: "#fff",
        padding: "12px 20px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        position: "relative",
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "#013a73",
        },
        "&.active": {
            backgroundColor: "#013a73",
            "&::after": {
                content: '""',
                position: "absolute",
                right: 0,
                top: 0,
                width: "4px",
                height: "100%",
                background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)"
            }
        },
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: "16px"
        },
    },


    "& .childItem": {
        fontWeight: 400,
        fontSize: "14px",
        "&:hover": {
            backgroundColor: "#0C4387",
        },
        "&.active": {
            backgroundColor: "#0C4387",
        },
        "& .MuiTypography-root": {
            fontWeight: 400,
            fontSize: "14px"
        }
     },


    "& .userInfo": {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif;",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginTop: "40px",
        height: "100%",
        justifyContent: "end",
        paddingBottom: "30px",
        boxSizing: "border-box",

        "& .avatar": {
            background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
            width: "48px",
            height: "48px",
            color: "#00184C"
        },
        "& .userDetails": {
            "& .userName": {
                fontSize: "16px",
                color: "#fff",
                fontWeight: 700,
            },
            "& .userOrg": {
                fontSize: "12px",
                fontWeight: 400,
                color: "#E4C9F3",
            },
        },
        "& .logout": {
            color: "white",
            cursor: "pointer",
        },
    },

    "& .content": {
        flexGrow: 1,
        backgroundColor: "#E9E9EA",
        padding: "20px",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
});
